<script setup>
import {Chart} from "chart.js";
import {onMounted, ref, defineProps, computed, onUnmounted} from "vue";
import {useStore} from "vuex";
import {
  chartJsTooltip,
  // Utils
} from "@/utils"
import moment from "moment";

const store = useStore();
const dateRange = computed(() => store.getters['crm/dashboard/getDateRange'])

const cursorX = ref(null)

const props = defineProps({
  actualData: {
    type: Array,
    required: true,
  },

  previousData: {
    type: Array,
    required: true,
  },

  color: {
    type: String,
    required: true,
  },

  labels: {
    type: Array,
    required: true,
  }
})

const colors = {
  invoice: {
    actualColor: {
      r: 48, g: 87, b: 242
    },
    previousColor: {
      r: 197, g: 197, b: 197
    }
  },

  opportunities: {
    actualColor: {
      r: 92, g: 184, b: 104
    },
    previousColor: {
      r: 197, g: 197, b: 197
    }
  }
};

const months = moment.monthsShort();


const quarterLabel = computed(() => {
  return months.slice(
    (months.findIndex((month) => month === moment(dateRange.value.startDate).format('MMM'))),
    ((months.findIndex((month) => month === moment(dateRange.value.endDate).format('MMM'))) + 1))
})

const diff = computed(() => {
  if (moment(dateRange.value.endDate).diff(moment(dateRange.value.startDate), 'days') <= 7) {
    return moment().localeData().weekdaysShort()
  }

  if (moment(dateRange.value.endDate).diff(moment(dateRange.value.startDate), 'weeks') <= 4) {
    return Array.from({length: moment().daysInMonth()}, (v, i) => i + 1)
  }

  if (moment(dateRange.value.endDate).diff(moment(dateRange.value.startDate), 'months') <= 6) {
    return quarterLabel.value
  }
  return months
})

const labels = {
  week: moment().localeData().weekdaysShort(),
  month: Array.from({length: moment().daysInMonth()}, (v, i) => i + 1),
  quarter: quarterLabel.value,
  semester: quarterLabel.value,
  year: months,
  custom: diff.value,
};


function getGradient(ctx, chartArea, color) {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(0, `rgba(${color.r}, ${color.g}, ${color.b}, 0)`);
  gradient.addColorStop(1, `rgba(${color.r}, ${color.g}, ${color.b}, 8)`);

  return gradient;
}

const earningsChart = ref(null);

// const maxData = computed(() => Math.max(Math.max(...props?.actualData), Math.max(...props?.previousData)))
function setCursorXPosition(event) {
  cursorX.value = event.clientX;
}

const chart = ref();

function createChart() {
  const ctx = earningsChart.value.getContext('2d');

  const options = {
    type: "line",
    data: {
      labels: labels[dateRange.value.value],
      datasets: [
        {
          label: dateRange.value.currentLabel.toUpperCase(),
          fill: true,
          backgroundColor: function (context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;
            if (!chartArea) {
              return;
            }
            return getGradient(ctx, chartArea, colors[props.color].actualColor);
          },
          borderColor: `rgba(${colors[props.color].actualColor.r}, ${colors[props.color].actualColor.g}, ${colors[props.color].actualColor.b}, 1)`,
          data: props.actualData,
          lineTension: 0.5,
          borderWidth: 3,
          pointRadius: 0,
        },
        {
          label: dateRange.value.label.toUpperCase(),
          fill: true,
          backgroundColor: function (context) {
            const chart = context.chart;
            const {ctx, chartArea} = chart;
            if (!chartArea) {
              return;
            }
            return getGradient(ctx, chartArea, colors[props.color].previousColor);
          },
          borderColor: `rgba(${colors[props.color].previousColor.r}, ${colors[props.color].previousColor.g}, ${colors[props.color].previousColor.b}, 1)`,
          data: props.previousData,
          lineTension: 0.5,
          borderWidth: 3,
          pointRadius: 0,
          borderDash: [5, 5]
        },

      ]
    },
    options: {
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) => {
            chartJsTooltip(
              context,
              dateRange.value.unitType,
              dateRange.value.label,
              `rgba(${colors[props.color].actualColor.r}, ${colors[props.color].actualColor.g}, ${colors[props.color].actualColor.b}, 1)`,
              dateRange.value.currentLabel,
              `rgba(${colors[props.color].previousColor.r}, ${colors[props.color].previousColor.g}, ${colors[props.color].previousColor.b}, 1)`,
              false,
              cursorX.value
            )
          }
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      layout: {
        padding: 0
      },

      // scales: {
      //   y: {
      //     ticks: {
      //       // Include a dollar sign in the ticks
      //       callback: function (value) {
      //         return  value.toLocaleString(toggleCurrency().locale, {
      //           // style: 'decimal',
      //           // currency: toggleCurrency().currency,
      //           options: { notation: 'compact',compactDisplay: 'short'}
      //         });
      //       }
      //     }
      //   }
      // }
    }
  };

  chart.value = new Chart(ctx, options);
}

function updateChart() {
  if (chart.value) {
    chart.value.resize();
  }
}

onMounted(() => {
  createChart()
  window.addEventListener('resize', updateChart);
  window.addEventListener('mousemove', setCursorXPosition);

});


onUnmounted(() => {
  window.removeEventListener('mousemove', setCursorXPosition);
  window.removeEventListener('resize', updateChart);
})

</script>


<template>
  <div style="height: 485px">
    <canvas
      ref="earningsChart"
      class="w-100 h-auto"
    />
  </div>
</template>

<style scoped lang="scss">

</style>